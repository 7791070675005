.xs {
  font-size: 12px;
}

.sm {
  font-size: 16px;
}

.md {
  font-size: 24px;
}

.lg {
  font-size: 36px;
}

.xl {
  font-size: 48px;
}
