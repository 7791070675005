.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  max-width: 640px;
  margin: 0px auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
}
