.input {
  min-height: 44px;
  padding: 8px;
  background-color: #ffffff;
  outline-style: solid;
  outline-width: 2px;
  outline-color: #c0c0c0;
  border-radius: 4px;
}

.error {
  outline-color: #d04040;
}

.input:focus {
  outline: solid 2px #008080;
}

.input:disabled {
  background-color: #f0f0f0;
}

.input::placeholder {
  color: #c0c0c0;
}
