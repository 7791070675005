.container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 64px;
  padding: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.logo {
  line-height: 1.2;
}

.github {
  position: relative;
  width: 28px;
  height: 28px;
}

.github:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.1);
}

.github:active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.2);
}
